import gql from 'graphql-tag';

export const TROCAR_SENHA = gql`
  mutation trocarSenha($novaSenha: String, $senhaAtual: String) {
    trocarSenha(novaSenha: $novaSenha, senhaAtual: $senhaAtual) {
      tokenClienteRegistrado
    }
  }
`;

export const TROCAR_SENHA_ADMIN = gql`
  mutation trocarSenhaAdminComercial($novaSenha: String, $senhaAtual: String) {
    trocarSenhaAdminComercial(novaSenha: $novaSenha, senhaAtual: $senhaAtual) {
      id
    }
  }
`;

export const ATUALIZAR_COMERCIAL = gql`
  mutation atualizaDadosComercial($comercial: ComercialInput) {
    atualizaDadosComercial(comercial: $comercial) {
      id
    }
  }
`;

export const CRIAR_ANUNCIANTE = gql`
  mutation criarAnunciante($anunciante: AnuncianteInput) {
    createAnunciante(anunciante: $anunciante) {
      id
    }
  }
`;

export const ATUALIZAR_ANUNCIANTE = gql`
  mutation atualizarAnunciante($anunciante: AnuncianteInput) {
    updateAnunciante(anunciante: $anunciante) {
      id
    }
  }
`;

export const GERAR_USUARIO_SENHA = gql`
  mutation gerarUsuarioSenhaAnunciante($anunciante: AnuncianteInput) {
    gerarUsuarioSenhaAnunciante(anunciante: $anunciante)
  }
`;

export const CRIAR_CAMPANHA = gql`
  mutation criarCampanha(
    $campanha: CampanhaInput
    $parcelamento: ParcelamentoDTOInput
  ) {
    createCampanha(
      campanha: $campanha,
      parcelamento: $parcelamento
    ) {
      id
    }
  }
`;

export const CRIAR_EMAIL_CONTATO = gql`
  mutation criarEmailContato(
    $mensagem: String
    $telefone: String
    $titulo: String
    $email: String
    $tipoDuvida: TipoEmailContato
  ) {
    createEmailContato(
      emailContato: {
        mensagem: $mensagem
        telefone: $telefone
        titulo: $titulo
        email: $email
        tipoDuvida: $tipoDuvida
      }
    ) {
      id
    }
  }
`;

export const GERAR_TOKEN_ANUNCIANTE = gql`
  mutation accessTokenAnuncianteByComercial($id: UUID) {
    accessTokenAnuncianteByComercial(id: $id) {
      accessToken
      tokenType
      expiresIn
      refreshToken
      scope
    }
  }
`;

export const CRIAR_ORCAMENTO = gql`
  mutation createOrcamento(
    $orcamento: OrcamentoInput
  ) {
    orcamento: createOrcamento(
      orcamento: $orcamento
    ) {
      id
    }
  }
`;

export const UPDATE_ORCAMENTO = gql`
  mutation updateOrcamento(
    $orcamento: OrcamentoInput
  ) {
    updateOrcamento(
      orcamento: $orcamento
    ) {
      id
    }
  }
`;

export const UPDATE_ORCAMENTO_FATURAMENTO = gql`
  mutation updateOrcamentoFaturamento(
    $orcamento: OrcamentoInput
  ) {
    updateOrcamentoFaturamento(
      orcamento: $orcamento
    ) {
      id
    }
  }
`;

export const UPDATE_SITUACAO_PROPOSTA = gql`
mutation updateSituacaoProposta(
  $propostaId: UUID,
  $situacao: OrcamentoSituacao
) {
  updateSituacaoProposta(
    propostaId: $propostaId,
    situacao: $situacao
  ) 
}
`;

export const DELETE_ORCAMENTO = gql`
  mutation deleteOrcamento($orcamento: OrcamentoInput) {
    deleteOrcamento(orcamento: $orcamento)
  }
`;

export const DELETE_PROPOSTA = gql`
  mutation deleteProposta($proposta: PropostaInput) {
    deleteProposta(proposta: $proposta)
  }
`;

export const CREATE_EVENTO_AGENDA = gql`
  mutation createEventoAgenda($eventoAgenda: EventoAgendaInput){
    createEventoAgenda(eventoAgenda: $eventoAgenda){
      id
    }
  }
`;

export const  UPDATE_EVENTO_AGENDA = gql`
mutation updateEventoAgenda($eventoAgenda: EventoAgendaInput){
  updateEventoAgenda(eventoAgenda: $eventoAgenda){
    id
  }
}
`;

export const DELETE_BY_ID_EVENTO_AGENDA = gql`
  mutation deleteByIdEventoAgenda($id: UUID){
    deleteByIdEventoAgenda(id: $id)
  }
 
`;

export const UPDATE_AGENCIA = gql`
  mutation updateAgencia($agencia: AgenciaInput) {
    updateAgencia(agencia: $agencia) {
      id
    }
  }
`;

export const UPDATE_EXECUTIVO = gql`
  mutation updateComercialExecutivo($executivo: ComercialExecutivoInput) {
    updateComercialExecutivo(executivo: $executivo) {
      id
    }
  }
`;

export const GERAR_PEDIDO = gql`
  mutation gerarPedido($orcamento: OrcamentoInput) {
    gerarPedido(orcamento: $orcamento)
  }
`;

export const UPDATE_REPROVADO_MOTIVO = gql`
  mutation updateReprovadoMotivo($proposta: PropostaInput, $motivo: String) {
    updateReprovadoMotivo(proposta: $proposta, motivo: $motivo)
  }
`;

export const UPDATE_DATA_RETORNO_PROPOSTA = gql`
  mutation updatePropostaDataRetorno($proposta: PropostaInput, $dataRetorno: LocalDateTime) {
    updatePropostaDataRetorno(proposta: $proposta, dataRetorno: $dataRetorno)
  }
`;

export const CRIAR_PROPOSTA = gql`
  mutation createProposta($proposta: PropostaInput) {
    createProposta(proposta: $proposta) {
      id
    }
  }
`;

export const EXCLUIR_AGENCIA = gql`
  mutation excluirAgencia($agencia: AgenciaInput){
    excluirAgencia(agencia: $agencia)
  }
`;

export const EXCLUIR_COMERCIAL_EXECUTIVO = gql`
  mutation excluirComercialExecutivo($executivo: ComercialExecutivoInput){
   excluirComercialExecutivo(executivo: $executivo)
  }
`;

export const DUPLICAR_ORCAMENTO_PROPOSTA = gql`
  mutation duplicarOrcamento($orcamento: OrcamentoInput){
    duplicarOrcamento(orcamento: $orcamento) {
      id
    }
  }
`;

export const GERAR_CAMPANHAS_ORCAMENTO = gql`
  mutation gerarPedidosOrcamento(
    $orcamento: OrcamentoInput
    $campanha: CampanhaInput
    $parcelamento: ParcelamentoDTOInput
  ) {
    gerarPedidosOrcamento(orcamento: $orcamento, campanha: $campanha, parcelamento: $parcelamento)
  }
`;

export const REPROVAR_PEDIDO = gql`
  mutation reprovarPedido($orcamento: OrcamentoInput) {
    reprovarPedido(orcamento: $orcamento)
  }
`;

export const CRIAR_COMERCIAL = gql`
  mutation criarComercial($comercial: ComercialInput) {
    comercial: createComercial(comercial: $comercial) {
      id
    }
  }
`;

export const GERAR_NOVA_SENHA_COMERCIAL = gql`
  mutation gerarNovaSenhaUsuarioComercial($comercial: ComercialInput) {
    senha: gerarNovaSenhaUsuarioComercial(comercial: $comercial)
  }
`;

export const GERAR_TOKEN_COMERCIAL = gql`
  mutation accessTokenComercialByAdmin($id: UUID) {
    accessTokenComercialByAdmin(id: $id) {
      accessToken
      tokenType
      expiresIn
      refreshToken
      scope
    }
  }
`;

export const ESTORNO_BAIXA_MOVIMENTACAO_FINANCEIRA = gql`
  mutation EstornoBaixaMovimentacaoFinanceira($id: UUID) {
    estornoBaixaMovimentacaoFinanceira(id: $id) {
      id
    }
  }
`;

export const BAIXA_MOVIMENTACAO_FINANCEIRA = gql`
  mutation baixaMovimentacaoFinanceira($movimentacao: MovimentacaoFinanceiraInput) {
    baixaMovimentacaoFinanceira(movimentacao: $movimentacao) {
      id
    }
  }
`;

export const BAIXAR_MULTIPLAS_MOVIMENTACOES = gql`
  mutation baixarMultiplasMovimentacoes($ids: [UUID], $documento: ImagemInput) {
    baixas: baixarMultiplasMovimentacoes(ids: $ids, documento: $documento)
  }
`;

export const REPROVAR_MULTIPLAS_MOVIMENTACOES = gql`
  mutation reprovarMultiplasMovimentacoes($ids: [UUID]) {
    reprovados: reprovarMultiplasMovimentacoes(ids: $ids)
  }
`;

export const ESTORNAR_MULTIPLAS_MOVIMENTACOES = gql`
  mutation estornarMultiplasMovimentacoes($ids: [UUID]) {
    estornos: estornarMultiplasMovimentacoes(ids: $ids)
  }
`;

export const MARCAR_ERRO_MULTIPLAS_MOVIMENTACOES = gql`
  mutation marcarErroMultiplasMovimentacoes($ids: [UUID]) {
    movimentacoes: marcarErroMultiplasMovimentacoes(ids: $ids)
  }
`;

export const REPROVAR_MOVIMENTACAO_FINANCEIRA = gql`
  mutation reprovarMovimentacaoFinanceira($id: UUID, $observacao: String) {
    reprovarMovimentacaoFinanceira(id: $id, observacao: $observacao) {
      id
    }
  }
`;

export const GERAR_CAMPANHAS = gql`
  mutation gerarCampanha($orcamento: OrcamentoInput) {
    gerarCampanha(orcamento: $orcamento)
  }
`;

export const UPDATE_VALOR_REAJUSTE = gql`
  mutation updateValorReajuste($orcamento: OrcamentoInput) {
    updateValorReajuste(orcamento: $orcamento) {
      id
    }
  }
`;