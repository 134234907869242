import {Instagram, Mail, Phone} from '@mui/icons-material';
import {Grid, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography} from '@mui/material';
import moment from 'moment';
import React from 'react';
import {useSelector} from 'react-redux';
import {ReactComponent as KmMidiaLogo} from '../../assets/images/km-midia-logo.svg';
import Number from '../../utils/number';
import string from '../../utils/string';

const Divisor = require('../../assets/images/divisor-relatorio.png');

const comercialKm = {
  nomeRazao: 'KM MONEY SISTEMAS LTDA',
  cpfCnpj: '33793168000150',
  telefone: '44999150120',
  email: 'atendimento@kmmidia.com.br',
  emailContato: 'atendimento@kmmidia.com.br',
  instagram: '@kmmidiaooh',
  endereco: {
    logradouro: 'Av. das Nações Unidas',
    numero: '12901',
    complemento: 'Torre Norte - 25º andar - Sala 160',
    bairro: 'Brooklin Paulista',
    cep: '04578-910',
    cidade: {
      nome: 'São Paulo',
      uf: 'SP',
    },
  },
};

const ImpressaoPI = ({proposta, orcamento, faturamento}) => {
  const {role, comercialResponsavel} = useSelector((store) => store.Auth);
  const comercial = role === 'COMERCIAL' ? comercialKm : comercialResponsavel;
  const {itens = []} = orcamento;
  const valorTotalMensal = itens.map((item) => item.quantidadeVeiculos * orcamento.quantidadeMeses * Number.stringToFloat(item.valorMensalVeiculo)).reduce((total, item) => total + item, 0);
  const valorTotalDesconto = itens.map((item) => item.quantidadeVeiculos * orcamento.quantidadeMeses * Number.stringToFloat(item.valorDescontoPorVeiculo)).reduce((total, item) => total + item, 0);
  const valorTotalMensalComDesconto = itens.map((item) => Number.stringToFloat(item.valorTotalCidade) * orcamento.quantidadeMeses).reduce((total, item) => total + item, 0);
  const valorTotalLiquido = (orcamento.valorTotalVeiculacaoMensal - Number.stringToFloat(orcamento.comissaoAgencia)) * orcamento.quantidadeMeses;
  const veiculacao = Array.from(Array(Number.safeParseInt(faturamento.parcelas)).keys());
  const parcelaVeiculacao =
    faturamento.faturarPor?.value === 'VALOR_LIQUIDO' ? valorTotalLiquido / faturamento.parcelas : (orcamento.valorTotalVeiculacaoMensal * orcamento.quantidadeMeses) / faturamento.parcelas;

  const calcularDataParcela = (data) => {
    const diaSemana = data.isoWeekday();

    if ([6, 7].includes(diaSemana)) return data.isoWeekday(5).format('DD/MM/YYYY');

    return data.format('DD/MM/YYYY');
  };

  return (
    <Grid item xs={12} sx={styles.reportContainer}>
      <Grid item xs={12} sx={styles.reportHeader}>
        <Grid item xs={10}>
          <Typography sx={styles.titleReport} style={{fontWeight: '700'}}>
            PEDIDO DE INSERÇÃO
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <KmMidiaLogo style={styles.titleLogo} />
        </Grid>
      </Grid>
      <Grid sx={styles.contratada}>
        <Typography sx={styles.subTitle}>
          <b>I. Contratada:</b>
        </Typography>
        <Grid container direction="row">
          <Typography sx={styles.textContainer} style={{width: '55%'}}>
            Razão social: <b>{comercial.nomeRazao}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '20%'}}>
            CNPJ: <b>{string.formatCpfCnpj(comercial.cpfCnpj)}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '25%'}}>
            Insc. estadual:
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '45%'}}>
            Endereço: <b>{comercial.endereco.logradouro}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '15%'}}>
            Nº: <b>{comercial.endereco.numero}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '40%'}}>
            Bairro: <b>{comercial.endereco.bairro}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '85%'}}>
            Comp: <b>{comercial.endereco.complemento}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '15%'}}>
            Cep: <b>{string.formatCep(comercial.endereco.cep)}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '35%'}}>
            Cidade: <b>{comercial.endereco.cidade.nome}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '15%'}}>
            Estado: <b>{comercial.endereco.cidade.uf}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '20%'}}>
            Telefone: <b>{string.formatTelefone(comercial.telefone)}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '30%'}}>
            E-mail: <b>{comercial.email}</b>
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={styles.contratada}>
        <Typography sx={styles.subTitle}>
          <b>II. Contratante:</b>
        </Typography>
        <Grid container direction="row">
          <Typography sx={styles.textContainer} style={{width: '80%'}}>
            Razão Social: <b>{proposta?.anunciante?.nomeRazao}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '20%'}}>
            CNPJ: <b>{string.formatCpfCnpj(proposta?.anunciante?.cnpj)}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '80%'}}>
            Nome fantasia: <b>{proposta?.anunciante?.nomeFantasia}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '20%'}}>
            Insc. estadual:<b>{proposta?.anunciante?.inscricaoEstadual}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '45%'}}>
            Endereço: <b>{proposta?.anunciante?.endereco?.logradouro}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '15%'}}>
            Nº: <b>{proposta?.anunciante?.endereco?.numero}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '40%'}}>
            Bairro: <b>{proposta?.anunciante?.endereco?.bairro}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '30%'}}>
            Comp: <b>{proposta?.anunciante?.endereco?.complemento}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '15%'}}>
            Cep: <b>{string.formatCep(proposta?.anunciante?.endereco?.cep)}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '40%'}}>
            Cidade: <b>{proposta?.anunciante?.endereco?.cidade?.nome}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '15%'}}>
            Estado: <b>{proposta?.anunciante?.endereco?.cidade?.uf}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '25%'}}>
            Telefone fixo: <b>{string.formatTelefone(proposta?.anunciante?.telefone)}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '25%'}}>
            Telefone cel: <b>{string.formatTelefone(proposta?.anunciante?.celular)}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '50%'}}>
            E-mail: <b>{proposta?.anunciante?.email}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '40%'}}>
            Resp. Marketing: <b>{proposta?.anunciante?.marketingNome}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '30%'}}>
            Telefone: <b>{string.formatTelefone(proposta?.anunciante?.marketingTelefone)}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '30%'}}>
            E-mail: <b>{proposta?.anunciante?.marketingEmail}</b>
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={styles.contratada}>
        <Typography sx={styles.subTitle}>
          <b>III. Dados do contrato:</b>
        </Typography>
        <Grid container direction="row">
          <Grid container item xs={3} direction="column" sx={styles.textContainer}>
            <Typography>Data da contratação:</Typography>
            <Typography>
              <b>{moment().format('DD/MM/YYYY')}</b>
            </Typography>
          </Grid>
          <Grid container item xs={3} direction="column" sx={styles.textContainer}>
            <Typography>Prazo do contrato:</Typography>
            <Typography>
              <b>{`${orcamento.quantidadeMeses} período${orcamento.quantidadeMeses === 1 ? '' : 's'} de ${orcamento.diasPeriodo?.value} dias`}</b>
            </Typography>
          </Grid>
          <Grid container item xs={2} direction="column" sx={styles.textContainer}>
            <Typography>Quantidade de veículos:</Typography>
            <Typography>
              <b>{orcamento.totalVeiculos}</b>
            </Typography>
          </Grid>
          <Grid container item xs={4} direction="column" sx={styles.textContainer}>
            <Typography>Data de veiculação:</Typography>
            <Typography>
              <b>
                Início: {moment(faturamento.dataInicioVeiculacao).format('DD/MM/YYYY')} - Término:{' '}
                {moment(faturamento.dataInicioVeiculacao)
                  .add(orcamento.quantidadeMeses * orcamento.diasPeriodo?.value, 'day')
                  .format('DD/MM/YYYY')}
              </b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={styles.cidades}>
        <Typography sx={styles.subTitle}>
          <b>IV. Detalhes da veiculação:</b>
        </Typography>
        <Grid container direction="row">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={styles.textContainer}>Cidades de veiculação</TableCell>
                <TableCell sx={styles.textContainer}>Tipo de anúncio</TableCell>
                <TableCell sx={styles.textContainer}>Nº de veículos</TableCell>
                <TableCell sx={styles.textContainer}>Duração campanha</TableCell>
                <TableCell sx={styles.textContainer}>Valor unitário</TableCell>
                <TableCell sx={styles.textContainer}>Valor total</TableCell>
                <TableCell sx={styles.textContainer}>Desconto</TableCell>
                <TableCell sx={styles.textContainer}>Valor total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itens.map((item, index) => {
                const valorTotal = item.quantidadeVeiculos * Number.stringToFloat(item.valorMensalVeiculo) * orcamento.quantidadeMeses;
                const valorDesconto = item.quantidadeVeiculos * Number.stringToFloat(item.valorDescontoPorVeiculo) * orcamento.quantidadeMeses;
                const valorTotalCidade = Number.stringToFloat(item.valorTotalCidade) * orcamento.quantidadeMeses;
                return (
                  <TableRow key={index}>
                    <TableCell sx={styles.textContainer}>{item.cidade.nomeEUf}</TableCell>
                    <TableCell sx={styles.textContainer}>{item.modeloAnuncio?.nome}</TableCell>
                    <TableCell sx={styles.textContainer}>{item.quantidadeVeiculos}</TableCell>
                    <TableCell sx={styles.textContainer}>{Number.safeParseInt(orcamento.diasPeriodo?.value) * Number.safeParseInt(orcamento.quantidadeMeses)} dias</TableCell>
                    <TableCell sx={styles.textContainer}>{Number.currencyFormat(item.valorMensalVeiculo)}</TableCell>
                    <TableCell sx={styles.textContainer}>{Number.currencyFormat(valorTotal)}</TableCell>
                    <TableCell sx={styles.textContainer}>{Number.currencyFormat(valorDesconto)}</TableCell>
                    <TableCell sx={styles.textContainer}>{Number.currencyFormat(valorTotalCidade)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell sx={styles.textFooter}>
                  <b>Total</b>
                </TableCell>
                <TableCell sx={styles.textFooter} />
                <TableCell sx={styles.textFooter}>
                  <b>{orcamento.totalVeiculos}</b>
                </TableCell>
                <TableCell sx={styles.textFooter} />
                <TableCell sx={styles.textFooter} />
                <TableCell sx={styles.textFooter}>
                  <b>{Number.currencyFormat(valorTotalMensal)}</b>
                </TableCell>
                <TableCell sx={styles.textFooter}>
                  <b>{Number.currencyFormat(valorTotalDesconto)}</b>
                </TableCell>
                <TableCell sx={styles.textFooter}>
                  <b>{Number.currencyFormat(valorTotalMensalComDesconto)}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{whiteSpace: 'nowrap', borderBottom: 'none'}}>
                  <Typography sx={styles.footerText} style={{height: 40}}>
                    <br />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>
      </Grid>
      <Grid sx={styles.contratada}>
        <Typography sx={styles.subTitle}>
          <b>V. Total da campanha:</b>
        </Typography>
        <Grid container direction="row">
          <Typography sx={styles.textContainer} style={{width: '75%'}}>
            <b>Total bruto negociado</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '25%'}}>
            <b>{Number.currencyFormat(orcamento.valorTotalVeiculacaoMensal * orcamento.quantidadeMeses)}</b>
          </Typography>

          <Typography sx={styles.textContainer} style={{width: '75%'}}>
            <b>Agência</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '25%'}}>
            <b>{Number.currencyFormat(Number.currencyToFloat(orcamento.comissaoAgencia) * orcamento.quantidadeMeses)}</b>
          </Typography>

          <Typography sx={styles.textContainer} style={{width: '75%'}}>
            <b>Total líquido</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '25%'}}>
            <b>{Number.currencyFormat(valorTotalLiquido)}</b>
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={styles.contratada}>
        <Typography sx={styles.subTitle}>
          <b>VI. Forma de pagamento:</b>
        </Typography>
        <Grid container direction="row">
          {veiculacao.map((parcela) => (
            <React.Fragment key={parcela}>
              <Typography sx={styles.textContainer} style={{width: '30%'}}>
                <b>{parcela + 1}ª Parcela: </b>
                {Number.currencyFormat(parcelaVeiculacao)}
              </Typography>
              <Typography sx={styles.textContainer} style={{width: '35%'}}>
                <b>Forma: </b>
                {faturamento.formaDePagamento?.label}
              </Typography>
              <Typography sx={styles.textContainer} style={{width: '35%'}}>
                <b>Vencimento: </b>
                {calcularDataParcela(moment(faturamento.vencimentoParcelas).add(parcela, 'M'))}
              </Typography>
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
      <Grid container sx={styles.assinatura}>
        <Grid container item xs={12}>
          <Typography align="center">
            <b>Faz parte do presente instrumento o contrato de locação de espaço destinado a publicidade em veículos (anexo), cujo a contratante declara ter tido acesso, no ato da assinatura.</b>
          </Typography>
        </Grid>
        <Grid container item xs={6}>
          <Typography align="center" className="borderTop">
            <b>{comercial.nomeRazao} (CONTRATADA)</b>
          </Typography>
        </Grid>
        <Grid container item xs={6}>
          <Typography align="center" className="borderTop">
            <b>(CONTRATANTE)</b>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{pageBreakBefore: 'always'}}>
        <Typography gutterBottom sx={{fontWeight: 'bold'}} pt={3}>
          NORMAS E PROCEDIMENTOS DE COMERCIALIZAÇÃO KM MÍDIA OOH
        </Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Prazo de Pagamento
        </Typography>
        <Typography paragraph>30 dias após o término da veiculação.</Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Prazo de Entrega
        </Typography>
        <Typography paragraph>
          Até 7 dias úteis após o recebimento do material e a aprovação da prova de cor a ser veiculado dentro das especificações técnicas da KM Mídia OOH para cidades com população acima de 100.000
          habitantes. Para cidades com população inferior a 100.000 habitantes, o prazo será de 10 dias úteis.
        </Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Pré-requisito
        </Typography>
        <Typography paragraph>O PI deverá ser entregue na aprovação do projeto, devidamente assinada pelo cliente e/ou agência.</Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Produção do Material
        </Typography>
        <Typography paragraph>A criação do material é de total responsabilidade do cliente. A impressão e instalação são de responsabilidade da KM Mídia OOH.</Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Especificações Técnicas
        </Typography>
        <Typography paragraph>
          No momento da aprovação da veiculação, a KM Mídia OOH enviará ao cliente um arquivo contendo todas as especificações técnicas dos materiais a serem produzidos. A KM Mídia OOH não aceitará
          materiais fora da especificação, sendo responsabilidade do cliente ou agência realizar os ajustes necessários.
        </Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Cancelamentos e Alterações
        </Typography>
        <Typography paragraph>Campanhas ativas não serão canceladas. Campanhas podem ser canceladas com até 30 dias de antecedência, a contar do início da veiculação.</Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Restrições de Exibição
        </Typography>
        <Typography paragraph>
          A publicidade veiculada não poderá conter propaganda político-partidária, de cunho religioso, de empresas que exploram o trabalho infantil ou que prejudicam o meio ambiente. Anúncios que
          promovam discriminação ou desrespeitem a legislação vigente também serão vetados, assim como aqueles restritos pelo Conselho Nacional de Autorregulamentação Publicitária (CONAR).
        </Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Informações Obrigatórias no PI
        </Typography>
        <Typography>Todo PI deverá incluir as seguintes informações, conforme proposta acordada entre o cliente e a KM Mídia OOH:</Typography>
        <Typography component="ul" paragraph>
          <li>Quilometragem estimada no período;</li>
          <li>Impactos externos estimados no período;</li>
          <li>Corridas estimadas no período;</li>
          <li>Impactos internos estimados no período.</li>
        </Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Check-in
        </Typography>
        <Typography paragraph>
          No momento da instalação, serão tiradas fotos que constarão no painel do anunciante, incluindo as seguintes informações por motorista: nome do motorista, cor do veículo, ano e modelo de
          fabricação, placa do veículo, odômetro inicial, quilometragem individual de cada motorista, data, horário, latitude e longitude, e a propaganda já adesivada conforme o modelo KM Mídia OOH
          contratado pelo cliente.
        </Typography>

        <Typography component="p" pt={3} sx={{fontWeight: 'bold', pageBreakBefore: 'always'}}>
          Check-out
        </Typography>
        <Typography paragraph>
          A desinstalação dos adesivos é de responsabilidade da KM Mídia OOH e será realizada no dia seguinte ao término da campanha. Todas as fotos estarão disponíveis no painel do anunciante em até
          5 dias úteis após o término da campanha, incluindo a foto do odômetro final, data, horário, latitude e longitude.
        </Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Login e Senha
        </Typography>
        <Typography paragraph>
          Cada anunciante receberá um login e senha exclusivos para acompanhar em tempo real a geração de todas as métricas e a evolução da campanha através do painel do anunciante, acessível pelo
          site: <a href="www.kmmidia.com.br">www.kmmidia.com.br</a>, na aba "Login Anunciante".
        </Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Falhas de Exibição
        </Typography>
        <Typography paragraph>
          Qualquer falha na exibição detectada pelo cliente deve ser comunicada à KM Mídia, que terá o prazo de 48 horas para apresentar uma solução durante o período de veiculação. Após a finalização
          da veiculação, não serão aceitas reivindicações (ex.: adesivos danificados).
        </Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Entrega de Resultados
        </Typography>
        <Typography paragraph>
          A KM Mídia OOH comercializa, através de seu dashboard, métricas como quilometragem, impactos externos, impactos internos, corridas e CPM, atrelados a um número total de carros para cada
          cidade. Todas as métricas, bem como a sugestão de quantidade de carros para o alcance das metas, são baseadas em pesquisas do Instituto Americano Nielsen e densidades demográficas por cidade
          (Fonte: IBGE). Será considerada uma entrega de 100% a campanha que alcançar 100% das métricas propostas e documentadas no PI, considerando a entrega total de carros.
        </Typography>

        <Typography component="p" sx={{fontWeight: 'bold'}}>
          Geração de Métricas KM Mídia
        </Typography>
        <Typography paragraph>
          A geração das métricas tem como base inicial a quilometragem real gerada pelos veículos, comprovada pelas fotos do odômetro inicial e final registradas no check-in e check-out. A partir da
          quilometragem, são geradas as demais métricas.
        </Typography>
      </Grid>
      <Grid item xs={12} className={`page-footer`} sx={styles.footer} style={{display: 'none'}}>
        <img alt={'divisor'} style={{width: '100%', margin: '5px 0'}} src={Divisor} />
        <Grid container direction="column" justifyContent="space-between">
          <Typography sx={styles.footerSubText}>
            {`${comercial.endereco.logradouro}, nº ${comercial.endereco.numero} - 
            ${comercial.endereco.complemento} - ${comercial.endereco.cidade.nome} - ${comercial.endereco.cidade.uf} - Brasil`}
          </Typography>
          <Grid container item direction="row" justifyContent="center">
            <Typography sx={styles.footerSubText}>
              <Phone sx={styles.footerIcon} />
              {string.formatTelefone(comercial.telefone)}
            </Typography>
            {comercial.emailContato && (
              <Typography sx={styles.footerSubText} style={{margin: '0 10px'}}>
                <Mail sx={styles.footerIcon} /> {comercial.emailContato}
              </Typography>
            )}
            {comercial.instagram && (
              <Typography sx={styles.footerSubText}>
                <Instagram sx={styles.footerIcon} /> {comercial.instagram}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  reportContainer: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '0 10px',
  },
  titleReport: {
    marginLeft: '15px',
    fontSize: 18,
    color: '#031663',
  },
  titleLogo: {
    width: '100%',
    height: '30px',
  },
  reportHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  footer: {
    margin: '0px 10px',
    position: 'fixed',
    width: '100%',
    top: '1140px',
  },
  footerIcon: {
    height: '15px',
    width: '15px',
    marginRight: '6px',
  },
  footerSubText: {
    display: 'flex',
    fontSize: 11,
    paddingRight: '10px',
    textAlign: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  contratoPagina: {
    breakInside: 'avoid',
    paddingTop: '15px',

    '& p': {
      fontSize: 10,
    },
  },
  contratada: {
    padding: '35px 0',
    margin: '-35px 0',
    breakInside: 'avoid',
    '& *': {
      fontSize: '10px !important',
    },
  },
  cidades: {
    marginTop: '30px',
    '& *': {
      fontSize: '10px !important',
    },
  },
  assinatura: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'space-around',
    marginTop: '20px',
    breakInside: 'avoid',
    '& *': {
      fontSize: '10px !important',
    },
    '& p': {
      padding: '5px',
      margin: '0 30px',
      width: '100%',
      height: '80px',
    },
    '& .borderTop': {
      borderTop: '1px solid black',
      marginTop: '40px',
    },
  },
  subTitle: {
    marginBottom: '5px',
  },
  textContainer: {
    padding: '0 5px',
    border: '1px solid black',
  },
  textFooter: {
    color: '#000',
    backgroundColor: '#d3d3d3',
    padding: '0 5px',
    border: '1px solid black',
  },
  paragrafo: {
    margin: '15px 0',
  },
};

export default ImpressaoPI;
